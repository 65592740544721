import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Paper, Dialog, DialogContent } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export function ShowOnceModal(props) {
    const style = {       
        boxShadow: 24,
        borderColor: 'var(--brand-main)',
        border: '1px solid var(--brand-main)',
        borderRadius: '16px',
        p: 4,
    };

    const [open, setOpen] = useState(false);
    const cookieName = props.cookieName;
    const [cookies, setCookie] = useCookies([cookieName]);
    
    useEffect(
        () => {
            if (cookies[cookieName] !== 1) {
                setOpen(true);
            } else {
                setOpen(false);
            }            
        }, [cookies, cookieName]
    )

    const handleClose = () => {
        setOpen(false);
        setCookie(cookieName, "1", 
            {
                path: "/",
                expires: new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
                // secure: true,                 
            }
        );
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            // This modal blocks the entire screen on mobile.
            sx={{
                display: {
                  xs: 'none',
                  md: 'block',
                  lg: 'block',
                },
                '& .MuiDialog-container': {
                  '& .MuiPaper-root': {
                    width: '100%',
                    maxWidth: '720px',
                  },
                },
              }}
            scroll='paper'
        >
            <DialogContent  sx={style} >
            <Paper>
                {<div style={{ position: 'absolute', top: 8, right: 8, padding: 8 }}>
                    <CloseIcon onClick={handleClose}/>
                </div>}
                {props.children}
            </Paper>
            </DialogContent>
        </Dialog>
    );
}