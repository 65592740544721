// import { Link as RouterLink } from 'react-router-dom';
import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from 'react-router';
import {  useAuthenticator } from '@aws-amplify/ui-react';
import { 
    Box, Button, Container, Divider, 
    // Link, List, ListItem, ListItemIcon, ListItemText, 
    Modal, Paper, Stack, Typography, useTheme 
} from "@mui/material";

import { ampli } from 'revelata-amplitude';
import CountUp from 'react-countup';
import Marquee from "react-fast-marquee";
    
// import CheckIcon from '@mui/icons-material/Check';
// import Carousel from 'react-material-ui-carousel';
// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';
// import { toDate as datefnstz_toDate } from 'date-fns-tz';
import Grid from '@mui/material/Unstable_Grid2';

// import items from './HomeData';
// import SpeedSvg from './svg/speed.svg';
import BoltSvg from './svg/icon_bolt.svg';
// import TrustSvg from './svg/trust.svg';
import ShieldSvg from './svg/icon_verified_user.svg';
// import EaseSvg from './svg/ease.svg';
import ExitToAppSvg from './svg/icon_exit_to_app.svg';
import FastGif from './gif/Fast.gif';
import TrustedGif from './gif/Trusted.gif';
import SimpledGif from './gif/Simple.gif';
// import RevelataSystemSvg from './svg/revelata_system.svg';
// import AuditUiSvg from './svg/audit_ui.svg';
// import ExcelIntegrationSvg from './svg/excel_integration.svg';
// import RevelataLogoSvg from "./svg/revelata_heptagonal_rose-logo_with_company_name.svg";
import HeroBackgroundWebp from './webp/hero_background.webp';
import HeptagonsSVG from './svg/pattern_heptagons.svg';
import HeroDashboardPng from './webp/hero_dashboard.webp';
import HeroDashboardFullPng from './png/hero_dashboard_full.png';

import BofaLogo from './png/bofa_logo.png'
import ExoduspointLogo from './png/exoduspoint_logo.png'
import FtserussellLogo from './png/lseg-ftse-russell_logo.png'
import WorldquantLogo from './png/worldquant_logo.png'
import BlackrockLogo from './svg/blackrock_logo.svg'
import BlackstoneLogo from './png/blackstone_logo.png'
import CarlyleLogo from './png/carlyle_logo.png'
import CitiLogo from './png/citi_logo.png'
import EvercoreLogo from './png/evercore_logo.png'
import GsLogo from './svg/gs_logo.svg'
import HsbcLogo from './png/hsbc_logo.png'
import JpmLogo from './png/jpm_logo.png'
import Point72Logo from './png/point72_logo.png'
import SpLogo from './png/sp_logo.png'
import TdLogo from './png/td_logo.png'
import TriumcapitalLogo from './png/triumcapital_logo.png'
import UbsLogo from './png/ubs_logo.png'

import GifPlayer from 'react-gif-player';
import { RevelataFooter } from 'revelata-common-ui';

// Highcharts.setOptions(
//     {
//         lang: {
//             thousandsSep: ',',
//             numericSymbols: ["k", "M", "B", "T", "P", "E"]
//         }
//     }
// )


function MyGifComponent({ gifUrl }) {
    const gifRef = useRef(null);

    const playerRef = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        const entry = entries[0];
        const ratio = entry.intersectionRatio;

        if (ratio > 0.85) {
          setTimeout(() => {
            playerRef.current.setState({
                playing: true
              });
          }, 100); // Optional delay (adjust as needed)
        } else if (ratio <= 0.85) {
          playerRef.pauseGif()
        }
      }, { threshold: [0.85] });
      
      observer.observe(gifRef.current);
      return () => {
        observer.disconnect();
      };
    }, [gifRef]);
    
  
    return (
      <div ref={gifRef}>
        <GifPlayer
          ref = {playerRef}
          gif={gifUrl}
          autoplay
          pauseRef={pause => playerRef.pauseGif = pause}
          style={{ maxWidth: '100%', objectFit: 'contain', height: 'auto',border: '1px solid #292929', borderRadius: '8px' }}
        />
      </div>
    );
  };

export default function Home() {
    const muiTheme = useTheme();
    const { route, user } = useAuthenticator((context) => [context.route, context.user]);
    const navigate = useNavigate();
    const location = useLocation();
    const [open, setOpen] = useState(false);

    function handleSignUpModalOpen() {
        setOpen(true);
    }

    function handleSignUpModalClose() {
        setOpen(false);
    }
    let from = location.state?.from?.pathname || '/app';
    useEffect(() => {
        if (route === 'authenticated') {
            ampli.identify(user.username);
            ampli.login();
            navigate(from, { replace: true });
        }
    });

    function AlphaModal() {
        return (
            <Modal open={open} onClose={handleSignUpModalClose} style={{ backdropFilter: "blur(5px)"}}>
                <Paper 
                    elevation={24}
                    sx={{
                    position: 'absolute', 
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '16px',
                    p: 10, 
                    maxWidth: "600px"                           
                }}>
                    <Typography variant="h4" color="var(--mui-palette-primary-dark)">
                        Thank you for your interest!
                    </Typography>
                    <Typography  sx={{ mt: 2 }}>
                        deepKPI is in private Beta at the moment.  Please check back later to join our next cohort of early adopters!
                    </Typography>
                </Paper>
            </Modal>
        )
    }

    // function formatDatapoints(datapoints) {
    //     return datapoints.map(
    //         (dp) => {                                
    //             const out = {
    //                 x: datefnstz_toDate(dp['displayDatetime'] + "T00:00:00", { timeZone: 'America/New_York'}).getTime(),
    //                 y: Number.parseFloat(dp['displayValue']),
    //                 marker: { radius: 5 },
    //             }
    //             // console.log(dp, out);
    //             return out;
    //         }
    //     ); 
    // }

    // function CarouselItem(props) {
    //     // console.log(props);
    //     const minX = datefnstz_toDate(props.data.minX + "T00:00:00", { timeZone: 'America/New_York'}).getTime();
    //     const maxX = datefnstz_toDate(props.data.maxX + "T00:00:00", { timeZone: 'America/New_York'}).getTime();

    //     const hcOptions = {
    //         credits: { enabled: false },                    
    //         xAxis: {            
    //             type: "datetime",
    //             className: "revelata-highcharts-font",
    //             labels: { 
    //                 style: { 
    //                     color: muiTheme.palette.text.primary, 
    //                     cursor: "default",
    //                     fontSize: "0.8rem"
    //                 }
    //             },
    //             min: minX, 
    //             max: maxX            
    //         },
    //         yAxis: {
    //             title: {
    //                 text: null,
    //             },
    //             className: "revelata-highcharts-font",
    //             labels: { 
    //                 style: { 
    //                     color: muiTheme.palette.text.primary, 
    //                     cursor: "default",
    //                     fontSize: "0.8rem"
    //                 }
    //             }
    //         },
    //         tooltip: {
    //             xDateFormat: "%Y-%m-%d",
    //             pointFormat: "{point.y}",
    //             //pointFormatter: function () { return data['data']['u'] + this.y },
    //             className: "highcharts-light revelata-highcharts-font"
    //         },
    //         series: [
    //             {   
    //                 name: '',
    //                 data: formatDatapoints(props.data.ts),//formatSeriesForHighCharts(data, selectedDatapoint && selectedDatapoint['chartId'] !== idx),  
    //                 color: 'var(--brand-main)',                              
    //             }
    //         ], 
    //         title: { 
    //             text: props.name, 
    //             style: {
    //                 fontSize: props.fontSize,
    //                 fontWeight: "400", 
    //                 fontFamily: "Figtree",
    //                 color: "var(--brand-analogous-50)"
    //             },                
    //         }, 
    //         legend:{ enabled: false },
    //         accessibility: {
    //             enabled: false,
    //         },
    //         chart: {
    //             borderRadius: "16px",                
    //             styleMode: true,                
    //             type: "line", //"spline"
    //             height: props.plotHeight,                
    //             time: {
    //                 timeZone: "America/New_York"
    //             }, 
    //             spacing: [20, 20, 20, 20]

    //         },
    //         plotOptions: {
    //             series: {                    
    //                 marker: {
    //                   enabled: true
    //                 }
    //             }
    //             // spline: { allowPointSelect: true },
    //             // line: { allowPointSelect: false }, 
    //             // series: {   
    //             //     marker: {
    //             //         states: {
    //             //             select: {
    //             //                 fillColor: '#ff0000',
    //             //             }
    //             //         }
    //             //     },         
    //             //     point:  { events: { select: function() {                    
    //             //         setSelectedDatapoint({'chartId': idx, 'dp': this.custom});                    
    //             //     } } }
    //             // }
    //         }
    //     }

    //     return (
    //             <HighchartsReact
    //                 highcharts={Highcharts}
    //                 options={hcOptions}
    //                 containerProps = {{ className: 'highcharts-dark revelata-highcharts-font' }}
    //             />
    //     )
    // }

    function ResponsiveLede() {
        const text_headline = "Every Key Metric, Instantly"
        const text_subheadline = "We surface public company KPIs from 10-K, 10-Q, and 8-K filings, so you can get to insight faster."
        
        return (
            <Box
            id="hero"
            sx={{
            //   mt: '-80px',
              p: 0,
            //   width: '100%',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
                backgroundImage:
                  `url(${HeroBackgroundWebp})`,
              }
            }
          >     
            <Stack sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
                <Box
                    sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: { xs: '100%', md: '52%' },
                    alignItems: 'center',
                    p: { xs: 2, md: 4 },
                    mt: { xs: 8, md: 14, lg: 14, xl: 16},                                 
                    }}
                >  
                    <Stack>
                        <Typography variant="h1" fontWeight="400" sx={{                        
                            color: "var(--brand-main)",
                            fontSize: { xs: "30px", md: "40px", lg: "48px", xl: "56px" }, 
                            textAlign: {xs: 'center', md: 'left'},
                            pl: { xs: 2, md: 5, lg: 10, xl: 20}, 
                            pr: { xs: 2, md: 5, lg: 10, xl: 30}, 
                            }}>
                            deepKPI
                        </Typography>
                        <Typography variant="h1" sx={{
                            fontSize: { xs: "30px", md: "40px", lg: "48px", xl: "56px" }, 
                            textAlign: {xs: 'center',md: 'left'},
                            pl: { xs: 2, md: 5, lg: 10, xl: 20}, 
                            pr: { xs: 2, md: 5, lg: 10, xl: 30},                             
                            }}>
                            {text_headline}
                        </Typography>
                        <Typography variant="h5" fontWeight="400"  sx={{
                            fontSize:  { xs: "14px", md: "18px", lg: "22px" }, 
                            mt: { xs: 3, md: 5 }, 
                            color: "#CBCBCB", 
                            textAlign: {xs: 'center',md: 'left'},
                            pl: { xs: 2, md: 5, lg: 10, xl: 20}, 
                            pr: { xs: 2, md: 5, lg: 10, xl: 30}, 
                        }}>
                            {text_subheadline}
                        </Typography>
                        <Box sx={{ 
                            display: 'flex', 
                            justifyContent: {xs: 'center',md: 'left'},  
                            mt: { xs: 4, md: 8 }, 
                            mb: { xs: 2 },
                            pl: { xs: 2, md: 5, lg: 10, xl: 20}, 
                            pr: { xs: 2, md: 5, lg: 10, xl: 30},                            
                        }}>
                            <Button 
                                variant='contained' 
                                sx={{borderRadius: 50, pl: 4, pr: 4, fontWeight: 'bold'}} 
                                size="large" 
                                href={process.env.REACT_APP_WWW_URL + "/signup"}
                                onClick={() => onClickSignUpButton("main")}>Sign Up</Button>
                        <AlphaModal />
                        </Box>
                    </Stack>
                </Box>
                <Box sx={{
                    mt: 20, 
                    display: { xs: 'none', md: 'flex', lg: 'flex' }, 
                    width: { xs: '100%', md: '48%' }, 
                    height: {xs: '100%', md: '100%'}, 
                    justifyContent: 'flex-end'}}>
                <img
                    src={HeroDashboardPng} // Replace with the URL or path to your image
                    style={{ height: '100%', width: '90%' }}
                />
                </Box>
                <Box sx={{ display: { xs: 'block', md: 'none', lg: 'none'}, mr:3, ml: 3, mt:3}}>
                    <img
                        src={HeroDashboardFullPng} // Replace with the URL or path to your image
                        style={{ maxWidth: '100%', objectFit: 'contain', height: 'auto' }}
                    />
                </Box>
            </Stack>
            </Box> 
        )
    };

    function ResponsiveFeatureSummarySection() {
        return (
            <Box sx={{ flexGrow: 1 }}>
                <Grid container sx={{ mt: { xs: 4, md: 8 }, mb: { xs: 6, md: 12 }, display: { xs: 'flex', md: 'flex', lg: 'flex' }, border: 0 }}>
                    <Grid item xs={3} md={3} lg={3}>
                        <Paper textAlign='center'>
                            <Typography variant="h5" fontSize={{ xs: '16px', md: '32px' }} align='center'>
                                <CountUp end={10000} enableScrollSpy scrollSpyOnce />+
                            </Typography>
                            <Typography align='center' fontSize={{ xs: '6px', md: '14px' }} noWrap sx={{ color: "#CBCBCB" }}>
                                COMPANIES
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={3} md={3} lg={3}>
                        <Paper textAlign='center'>
                            <Typography variant="h5" fontSize={{ xs: '16px', md: '32px' }} align='center'>
                                <CountUp end={20} enableScrollSpy scrollSpyOnce /> yrs
                            </Typography>
                            <Typography align='center' fontSize={{ xs: '6px', md: '14px' }} noWrap sx={{ color: "#CBCBCB" }}>
                                OF HISTORICALS
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={3} md={3} lg={3}>
                        <Paper textAlign='center'>
                            <Typography variant="h5" fontSize={{ xs: '16px', md: '32px' }} align='center'>
                                <CountUp end={550} enableScrollSpy scrollSpyOnce /> M
                            </Typography>
                            <Typography align='center' fontSize={{ xs: '6px', md: '14px' }} noWrap sx={{ color: "#CBCBCB" }}>
                                DATA POINTS AND GROWING
                            </Typography>
                        </Paper>
                    </Grid>

                    <Grid item xs={3} md={3} lg={3}>
                        <Paper textAlign='center'>
                            <Typography variant="h5" fontSize={{ xs: '16px', md: '32px' }} align='center'>
                                <CountUp end={1200} enableScrollSpy scrollSpyOnce />
                            </Typography>
                            <Typography align='center' fontSize={{ xs: '6px', md: '14px' }} noWrap sx={{ color: "#CBCBCB" }}>
                                AVG HRS PER YEAR SAVED
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        )
    }



    function ResponsiveMarqueeSection() {
      const logos = [
        { src: BlackstoneLogo, alt: 'Blackstone Logo' },
        { src: JpmLogo, alt: 'JPM Logo' },
        { src: CitiLogo, alt: 'Citi Logo' },
        { src: GsLogo, alt: 'GS Logo' },
        { src: HsbcLogo, alt: 'HSBC Logo' },
        { src: UbsLogo, alt: 'UBS Logo' },
        { src: TdLogo, alt: 'TD Logo' },
        { src: EvercoreLogo, alt: 'Evercore Logo' },
        { src: Point72Logo, alt: 'Point72 Logo' },
        { src: CarlyleLogo, alt: 'Carlyle Logo' },
        { src: TriumcapitalLogo, alt: 'Triumcapital Logo' },
        { src: SpLogo, alt: 'SP Logo' },
        { src: WorldquantLogo, alt: 'Worldquant Logo' },
        { src: ExoduspointLogo, alt: 'Exoduspoint Logo' },
        { src: FtserussellLogo, alt: 'Ftserussell Logo' },
        { src: BofaLogo, alt: 'Bofa Logo' },
        { src: BlackrockLogo, alt: 'Blackrock Logo' },
      ];
    
      return (
        <>
        <Stack sx={{display: {xs: 'none', md: 'flex', lg:'flex'},  mb: { xs: 6, md: 12 }}} alignItems="center" justifyContent="center">
          <Typography variant="h6" sx={{fontSize: { xs:"12px", md: "24px"}, fontWeight: "600", mb: { xs:3, md: 6 },  mt: { xs: 2, md: 4 }}}>
            Developed with feedback from professionals at:
          </Typography>
          <Marquee speed="25" autoFill gradient gradientColor='#0d0d0d' gradientWidth={100}>
            {logos.map((logo, index) => (
              <img
                key={index}
                alt={logo.alt}
                src={logo.src}
                style={{ height: "32px", marginRight: "64px" }} // Add margin-right
              />
            ))}
          </Marquee>
        </Stack>
        <Stack sx={{display: {xs: 'flex', md: 'none', lg:'none'},  mb: { xs: 6, md: 12 }}} alignItems="center" justifyContent="center">
          <Typography variant="h6" sx={{fontSize: { xs:"12px", md: "24px"}, fontWeight: "600", mb: { xs:3, md: 6 },  mt: { xs: 2, md: 4 }}}>
            Developed with feedback from professionals at:
          </Typography>
          <Marquee speed="25" autoFill>
            {logos.map((logo, index) => (
              <img
                key={index}
                alt={logo.alt}
                src={logo.src}
                style={{ height: "16px", marginRight: "32px" }} // Add margin-right
              />
            ))}
          </Marquee>
        </Stack>

</>

        
      );
    }


    const onClickSignUpButton = (header) => {     
        ampli.signUpButtonClick({ "Button Location": header});             
    }

    function ResponsiveFeaturesSection() {

        function ResponsiveSection({section_id, header_color, header_img, header, text1, text2, text3, list, section_img}) {
            const mdlgBackgroundColor = (section_id % 2 === 1) ? "#121212" : "";
            const xsBackgroundColor = (section_id % 2 === 0) ? "#121212" : "";
                        
            return (
                <>                
                <Box sx={{display: {xs: 'none', md: 'flex', lg:'flex'}, 
                             border: 0,
                            pt: "4vh", pb: "4vh", width: "100%"}} alignItems="center" justifyContent="center">
                        <Grid container justifyContent="center" alignItems="stretch">
                            {/* <Grid item md={1} lg={1} display="flex" justifyContent="center" /> */}
                            { section_id % 2 === 1 ? 
                                <Grid item md={6} lg={6} sx={{ m: 0 }} display="flex" justifyContent="center">
                                <MyGifComponent  gifUrl={section_img}></MyGifComponent>
                            </Grid>
                                : null
                            }
                            <Grid item xs={12} md={4} lg={4} mr={10} ml={10} justifyContent="center" maxWidth="800px">
                                <Stack direction="column" spacing={1} border={0}>
                                    <Stack direction="row" spacing={1}>
                                    <img alt="" src={header_img} height={33} width={33} />
                                    <Typography variant="h6" color={header_color}>{header}</Typography>
                                    </Stack>
                                    <Typography variant="h4" sx={{ fontSize: "32px", fontWeight: "700" }}>{text1}</Typography>
                                    <Typography variant="h6" pb={4} pr={12} pt={3} sx={{ fontSize: "16px", fontWeight: "600", color: "#CBCBCB" }}>{text3}</Typography>
                                    <Button variant="outlined" sx={{
                                        borderColor: "white",
                                        color: "white",
                                        textTransform: "none",
                                        width: "150px", // Set a fixed width for medium size (adjust if needed)
                                        borderRadius: 50,
                                        fontWeight: "600",
                                        }} href={process.env.REACT_APP_WWW_URL + "/signup"} onClick={() => onClickSignUpButton(header)}>
                                    Get started
                                    </Button>
                                </Stack>
                                </Grid>
                            { section_id % 2 === 1 ? 
                                null
                                : 
                                <Grid item md={6} lg={6} sx={{ m: 1 }} display="flex" justifyContent="center">
                                    <MyGifComponent gifUrl={section_img}></MyGifComponent>
                                </Grid>
                            }
                            {/* <Grid item md={1} lg={1} display="flex" justifyContent="center"/> */}
                        </Grid>
                </Box>
                <Box sx={{display: {xs: 'flex', md: 'none', lg:'none'},
                            pt: "4vh", pb: "4vh", pl: "3vw", pr: "3vw" }} alignItems="center" justifyContent="center">
                        <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={5}>
                            <Grid item xs={12} display="flex" justifyContent="center">
                                <Stack height="100%" direction="column" spacing={2} border={0}>
                                    <Stack direction="row" spacing={1}>
                                        <img alt="" src={header_img} height={33} width={33}/>
                                        <Typography variant="h5" color={header_color}>{header}</Typography>
                                    </Stack>
                                    <Typography variant="h4" sx={{'fontSize': "20px", fontWeight: '700', pl: 2}}>{text1}</Typography>
                                    <Typography pb={2} sx={{'fontSize': "14px", fontWeight: '400', color: "#CBCBCB", pl: 2}}>{text3}</Typography>

                                    
                                    <div style={{ marginLeft: 12, marginBottom: 24 }}>
                                    <Button variant="outlined" sx={{
                                        borderColor: "white",
                                        color: "white",
                                        textTransform: "none",
                                        width: "125px", // Set a fixed width for medium size (adjust if needed)
                                        borderRadius: 50,
                                        fontWeight: "600",
                                        }} href={process.env.REACT_APP_WWW_URL + "/signup"} onClick={() => onClickSignUpButton(header)}>
                                    Get started
                                    </Button>  
                                    </div>

                                    <MyGifComponent gifUrl={section_img}></MyGifComponent>                          
                                </Stack>                                
                            </Grid>
                        </Grid>
                </Box>
                </>
            )
        }

        const fast_section_props = {
            section_id: 0, 
            // header_color: "#2CFECC",
            header_img: BoltSvg, 
            header: "Fast", 
            text1: "Cut days down to seconds.", 
            text2: "With deepKPI, the average analyst saves up to 1,200 hrs/yr.", 
            text3: "Our platform automatically discovers and extracts time series buried in text and tables across filings, resulting in a comprehensive set of detailed, company-specific KPIs that can't be found anywhere else.", 
            list: [
                "10,000+ companies", 
                "Up to 20 years of historicals",
                "Over 550 million data points and growing",
                "Automatic updates, whenever filings are published"
            ], 
            section_img: FastGif
        };

        // const trusted_section_props = {
        //     section_id: 1, 
        //     header_img: BoltSvg, 
        //     header: "Fast", 
        //     text1: "Cut days down to seconds.", 
        //     text2: "With deepKPI, the average analyst saves up to 1,200 hrs/yr.", 
        //     text3: "Our platform automatically discovers and extracts time series buried in text and across filings, resulting in a comprehensive set of detailed, company-specific KPIs that can't be found anywhere else.", 
        //     list: [
        //         "10,000+ companies", 
        //         "Up to 20 years of historicals",
        //         "Over 550 million data points and growing",
        //         "Automatic updates, whenever filings are published"
        //     ], 
        //     section_img: RevelataSystemSvg
        // };
        const trusted_section_props = {
            section_id: 1, 
            // header_color: "#B354FE",
            header_img: ShieldSvg, 
            header: "Trusted", 
            text1: "Audit every data point with one click.", 
            text2: "We believe provenance is just as important as the data itself.", 
            text3: "We believe provenance is just as important as the data itself. Our customers must be able to trust our data. What better way than to show them the exact sentence it came from?",
            list: [
                "Trace every data point back to its source sentence",                 
                "No generative AI = no hallucinations",                
                // "24/7 data quality monitor automatically flags possible issues before publication"
            ], 
            section_img: TrustedGif
        }

        const simple_section_props = {
            section_id: 2, 
            // header_color: "#FE912C",
            header_img: ExitToAppSvg, 
            header: "Simple", 
            text1: "Works the way you do.", 
            text2: "deepKPI integrates seamlessly into your workflow.", 
            text3: "deepKPI integrates seamlessly into your workflow. Incorporate our KPI time series into your existing models or build new ones around ours without skipping a beat.", 
            list: [
                "Export directly into Excel", 
                "Audit from within Excel with a single click",
                "Exports work offline, so you can share",                
            ], 
            section_img: SimpledGif
        }
;
        return (
        <Box
            id="a"
            sx={{
            position: 'relative',
            width: '100%',
            backgroundRepeat: 'no-repeat',
            //   backgroundPosition: 'bottom left',
            backgroundSize: '1000px 1000px', // Adjust the dimensions as needed
            backgroundPosition: '-40% 160%', // Adjust the values as needed
            backgroundImage: `url(${HeptagonsSVG})`,
            }}
        >
            <Stack alignItems="center" justifyContent="center">
                <ResponsiveSection {...fast_section_props} />
                <ResponsiveSection {...trusted_section_props} />
                <ResponsiveSection {...simple_section_props} />
            </Stack>
        </Box>
        )
    }

    return (
      <Container disableGutters maxWidth="false" alignItems="center">
        <ResponsiveLede />
        <ResponsiveFeatureSummarySection />
        <ResponsiveMarqueeSection />
        <ResponsiveFeaturesSection />

        <Divider/>
        <RevelataFooter/>

      </Container>
    )
}