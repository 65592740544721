import './../../App.css';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
// import { ErrorBoundary } from 'react-error-boundary';
// import { ErrorBox } from '../ErrorBox';
import { Box, Typography } from '@mui/material';
import { DisplayedCikContext, SelectedDatapointContext } from './Contexts';
import ProvenancePane from './ProvenancePane';
import { decodeDatapoint } from './DatapointProvenanceApi';
import { ampli } from 'revelata-amplitude';

export function ProvenanceViewer() {
    const [params, setSearchParams] = useSearchParams();
    const [selectedDatapoint, setSelectedDatapoint] = useState(null);
    const [displayedCik, setDisplayedCik] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    useEffect( 
        () => {
            const pb = params.get('d');
            const dp = decodeDatapoint(pb);
            setSelectedDatapoint({dp: dp});
            setDisplayedCik(dp['cik']);            
            ampli.dataAudit( {                
                "Provenance Details": [
                    dp['cik'],
                    dp['accNo'], 
                    dp['seqNo'].toString(), 
                    JSON.stringify(dp['xpathNodes'])
                ], 
                "Provenance Caller": "docviewer",
                "Filing Date": dp['filingDate'],
                "Reporting Period": dp['reportingPeriod'],
                "Sentence":  dp['sentence'],
                "Form Type": dp['formType'],
                "Doc Type": dp['docType'],
                }
            );
        }, [params]
    );

    return (        
        <Box sx={{minWidth: "1280px", height: "calc(100vh - 15px - 85px)", maxHeight: "calc(100vh - 15px - 85px)"}}>                                
            {/* <ErrorBoundary FallbackComponent={ErrorBox}> */}
                <DisplayedCikContext.Provider value={[displayedCik, setDisplayedCik]}>
                    <SelectedDatapointContext.Provider value={[selectedDatapoint, setSelectedDatapoint]}>
                            <ProvenancePane provenancePaneHeight={"calc(100vh - 90px - 120px + 71px)"}/>
                    </SelectedDatapointContext.Provider>        
                </DisplayedCikContext.Provider>                
            {/* </ErrorBoundary> */}
            <Box position="absolute" bottom="10px" sx={{minWidth: "1280px", width: "100%", height: "10px", maxHeight: "10px"}}>  {/*display="flex" justifyContent="flex-end"*/}
                <Typography align="center" fontSize="0.6rem">Copyright &copy; 2024 Revelata, Inc.&nbsp;&nbsp;All rights reserved. <a href="/static/tos.html" _target="blank">Terms of Service</a></Typography>
            </Box>
        </Box>
    )
}