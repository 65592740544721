import './App.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ErrorBoundary } from 'react-error-boundary';

import Home from './components/Home';
import Support from './components/Support';
import AppView from './components/appview/AppView';
import { ProvenanceViewer } from './components/appview/ProvenanceViewer';
import { Amplify } from '@aws-amplify/core';
import { Authenticator } from '@aws-amplify/ui-react';

import { 
  getTheme, 
  AUTHN_CONFIG,
  RequireAuthN,
  ErrorBox, 
  TopAppBar
} from 'revelata-common-ui';

import { Box } from '@mui/material';

Amplify.configure(AUTHN_CONFIG)

// export function Home() {
//   return (
//     <Container>
//       <h1>This is the homepage</h1>
//     </Container>
//   )
// }

// export function NotFound() {
//   return (
//     <Stack alignItems="center" justifyContent="center" height="100%">
//         <Alert icon={<SentimentVeryDissatisfied fontSize="large" />} severity='error' fontSize="large">
//             <AlertTitle fontSize="large"><strong>Oops!</strong></AlertTitle>                
//             <Typography fontSize="large">
//               Something went wrong. The web address you were trying to reach doesn't exist. 
//             </Typography> 
//         </Alert>
//     </Stack> 
//   )
// }

export function App() {
  document.title = 'deepKPI by revelata | beta'
  const Theme = createTheme(getTheme('dark'));
  return (
    <>
    <ThemeProvider theme={Theme}>
      <Authenticator.Provider>
        <Router>
          <TopAppBar defaultTabValue={1}></TopAppBar>
          <Box sx={{ height: "calc(100vh - 50px)", minHeight: "calc(100vh - 50px)", pt: 8}}> 
            <ErrorBoundary FallbackComponent={ErrorBox}>
            <Routes>
              <Route path="/" element={<Home/>}/>
              <Route path="/support" element={<Support />}/>
              {/* <Route path="/login" element={<Login tab="signIn"/>}/> */}
              {/* <Route path="/signup" element={<Login tab="signUp"/>}/> */}
              <Route path="/docviewer" element={<ProvenanceViewer/>}/>
              <Route path="/app" element={ <RequireAuthN onFailureUrl={process.env.REACT_APP_DEEPKPI_URL}><AppView/></RequireAuthN> }/>
              {/* <Route path="/app/:ticker" element={ <RequireAuth><AppView/></RequireAuth> }/>
              <Route path="/app/:ticker/:formType" element={ <RequireAuth><AppView/></RequireAuth> }/>
              <Route path="/app/:ticker/:formType/:fromDate" element={ <RequireAuth><AppView/></RequireAuth> }/>
              <Route path="/app/:ticker/:formType/:fromDate/:toDate" element={ <RequireAuth><AppView/></RequireAuth> }/> */}
              {/* <Route path="/changepassword" element={
                <RequireAuth><ChangePassword/></RequireAuth>
              }/> */}
              {/* <Route path="/profile" element={
                <RequireAuth><UserProfile/></RequireAuth>
              }/> */}
              <Route path="*" element={<Home/>}/>
            </Routes>
            </ErrorBoundary>
          </Box>
        </Router>              
      </Authenticator.Provider>
      </ThemeProvider>
    </>    
  );
}

export default App;