import './../../App.css';
import { useContext, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import { Auth } from 'aws-amplify';
import {
    Accordion, AccordionDetails, AccordionSummary,
    FormGroup, FormControlLabel,
    Checkbox, Stack, CircularProgress, Typography, Tooltip,
    List, ListItem, ListItemText, Chip, Grid
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CallMergeOutlinedIcon from '@mui/icons-material/CallMergeOutlined';

import { makePostRequest } from 'revelata-common-ui';
import {
    SelectedDatapointContext,
    SelectedTableSeriesContext,
    DisplayedCikContext,
    SearchBarContext,
    DateRangeContext,
    OnboardingWizardContext,
    TableSeriesExpandStateContext
} from './Contexts';
import { ampli } from 'revelata-amplitude';
import { OnboardingWizard, advanceWizardState } from './Onboarding.js';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Highlighter } from '../TextHighlighter';
import { HEADER_DELIMITER } from './constants';


// const OneYearThreshold = 365 * 24 * 60 * 60 * 1000;


function DenseTable({ data, highlightValue, ticker}) {
    const [selectedDatapoint, setSelectedDatapoint] = useContext(SelectedDatapointContext);
    const [wizardState, setWizardState] = useContext(OnboardingWizardContext);
    const handleClick = (cellData) => {
        setSelectedDatapoint({
            "chartId": 0,
            "dp": {
                "xpathNodes": cellData.provenance.xpathNodes,
                "accNo": cellData.provenance.accNo,
                "seqNo": cellData.provenance.seqNo,
                "formType": cellData.provenance.formType,
                "docType": cellData.provenance.docType,
                "filingDate": cellData.provenance.filingDate,
                "reportingPeriod": cellData.provenance.reportingPeriod,
                "sentence": cellData.value,
            }
        })

        ampli.dataAudit({
            "Provenance Details": [
                cellData.provenance.cik,
                cellData.provenance.accNo,
                cellData.provenance.seqNo.toString(),
                JSON.stringify(cellData.provenance.xpathNodes)],
            "Filing Date": cellData.provenance.filingDate,
            "Reporting Period": cellData.provenance.reportingPeriod,
            "Sentence": cellData.value,
            "Display Value": cellData.value,
            "Provenance Caller": "app",
            "Query Symbol": ticker,
            "Chart Title": data.chartTitle,
            "Description": data.description,
            "Doc Type": cellData.provenance.docType,
            "Form Type": cellData.provenance.formType,
            "Score": data.score.aggregated,
            "Section Idx": data.sectionIdx,
            "Series Length": data.timeContextHeaders[0].date.length,
        }
        );
        advanceWizardState(5, wizardState, setWizardState);

    };
    const tableFontSize = "0.625rem"
    const tablePadding =  "2px 4px" 
    return (
        <TableContainer sx={{ backgroundColor: "white" }} component={Paper}>
            <Table sx={{minWidth: 650, tableLayout: "fixed"  }} size="small" aria-label="a dense table">
                <TableHead sx={{ backgroundColor: "#0094aa" }}>
                    <TableRow>
                        <TableCell  sx={{ fontWeight: "bold", fontSize: tableFontSize, width: "100px" }}>Date</TableCell>
                        {data["timeContextHeaders"][0]['date'].slice(1).map((date, dataIndex) => { return <TableCell key={dataIndex} align="right" sx={{ fontWeight: "bold", fontSize: tableFontSize, padding: tablePadding, width: "72px" }}>{date}</TableCell> })}
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ fontWeight: "bold", fontSize: tableFontSize }}>Fiscal</TableCell>
                        {data["timeContextHeaders"][1]['date'].slice(1).map((fiscalPeriod, fiscalIndex) => { return <TableCell key={fiscalIndex} align="right" sx={{ fontWeight: "bold", fontSize: tableFontSize, padding: tablePadding }}>{fiscalPeriod}</TableCell> })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data["tableSeries"].map((row, rowIndex) => {
                        const allEmpty = row['cell'].slice(1).every((otherCell) => otherCell.value === "" || otherCell.value === null || typeof otherCell.value === "undefined");
                        const backgroundColor = allEmpty ? "#d9d9d9" : "white";
                        return <TableRow
                            key={rowIndex}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row" style={{ fontWeight: "bold", backgroundColor: row['cell'][0].value.toLowerCase().includes(highlightValue.toLowerCase()) && row['cell'][0].value !== "" && highlightValue !== "" ? "#FFD700": ( allEmpty ? "#d9d9d9": "#dbfaff"), color: "black", fontSize: tableFontSize, padding: tablePadding }}>
                                {row['cell'][0].value}
                            </TableCell>
                            {row['cell'].slice(1).map((cell, cellIndex) => {
                                const onClick = cell.value ? () => handleClick(cell) : undefined;
                                const cursor = cell.provenance ? 'pointer' : 'default';
                                return <TableCell key={cellIndex} align="right" style={{ color: "#0000FF", cursor, backgroundColor, fontSize: tableFontSize, padding: tablePadding }} onClick={onClick}>{cell.value}</TableCell>
                            })}
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

Highcharts.setOptions(
    {
        lang: {
            thousandsSep: ',',
            numericSymbols: ["k", "M", "B", "T", "P", "E"]
        }
    }
)

function hasOnlyMinusOneKey(dict) {
    // Check if the dictionary is empty
    if (Object.keys(dict).length === 0) {
        return false; // Empty dictionary cannot have a single key
    }

    // Check if there's only one key and it's -1
    return Object.keys(dict).length === 1 && dict[-1] !== undefined;
}

function findDuplicateCharts(data, displayMask) {
    const duplicateIndexes = [];
    const seenCharts = new Map();

    data.forEach((item, index) => {
        const key = item.chartTitle + "@@@" + item.modifier;
        if (!displayMask.has(index) && item.shouldDisplay) {
            if (seenCharts.has(key)) {
                duplicateIndexes.push(index, seenCharts.get(key));
            } else {
                seenCharts.set(key, index);
            }
        }

    });

    return duplicateIndexes;
}


export function TableSeriesView({ idx, isFirstChart, data, expanded, flipExpandedState, latestFilingData, hasDuplicate, ticker, filterValue }) {

    let containsLatestFiling;
    containsLatestFiling = false;
    if ((Object.keys(latestFilingData).length === 0)) {
        containsLatestFiling = false;
    } else {
        const latestAccNos = Object.values(latestFilingData).map(item => item.acc_no);
        containsLatestFiling = latestAccNos.some(accNo => data.tableSeries.some(row => row.cell.some(item =>item.provenance && item.provenance.accNo === accNo)));
    }

    // const chartKey = useRef(0);

    const [wizardState, setWizardState] = useContext(OnboardingWizardContext);
    const [selectedDatapoint, setSelectedDatapoint] = useContext(SelectedDatapointContext);
    const [selectedTableSeries, setSelectedTableSeries] = useContext(SelectedTableSeriesContext);


    const handleCheckboxChange = (event) => {
        const updated = new Set([...selectedTableSeries]);
        let isAlreadySelected = updated.has(idx)
        if (isAlreadySelected) {
            updated.delete(idx);
        } else {
            updated.add(idx);
        }
        setSelectedTableSeries(updated);

        advanceWizardState(6, wizardState, setWizardState);

        
        ampli.timeSeriesChartViewSelection({
            "Query Symbol": ticker,
            "Chart Title": data.chartTitle,
            "Description": data.description,
            "Doc Type": data.docType,
            "Form Type": data.formType,
            "Score": data.score.aggregated,
            "Section Idx": data.sectionIdx,
            "Chart Index": idx,
            "Series Length": data.timeContextHeaders[0].date.length,
            "Contains Latest Filing": containsLatestFiling,
            "Expanded": !isAlreadySelected,
            "Has Duplicate Time Series": hasDuplicate
        })
    }


    return (
        <Accordion
            disableGutters
            expanded={expanded}
            onChange={
                () => {
                    flipExpandedState(idx, data.timeContextHeaders[0].date.length, hasDuplicate, containsLatestFiling);
                }
            }
            TransitionProps={{ unmountOnExit: true }}
        >
            <AccordionSummary sx={{ maxHeight: "25px", paddingLeft: "30px", paddingTop: "30px", paddingBottom: "30px" }} expandIcon={<ExpandMoreIcon />}>
                <Grid container spacing={0} alignItems="center">
                    <Grid item lg={10.5} xl={10.5}>
                        <FormGroup row={true}>
                            <FormControlLabel
                                control={
                                    isFirstChart ?
                                        <OnboardingWizard step={6} placement="bottom-start">
                                            <Checkbox size="small"
                                                checked={selectedTableSeries.has(idx)}
                                                onClick={(e) => e.stopPropagation()}
                                                onChange={handleCheckboxChange} />
                                        </OnboardingWizard>
                                        :
                                        <Checkbox size="small"
                                            checked={selectedTableSeries.has(idx)}
                                            onClick={(e) => e.stopPropagation()}
                                            onChange={handleCheckboxChange} />
                                }
                                label={
                                    <Stack direction="row">
                                        <Highlighter text={data['description']} keyword={filterValue} sx={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            display: "-webkit-box",
                                            WebkitLineClamp: "2",
                                            WebkitBoxOrient: "vertical",
                                            textAlign: "left",
                                            fontSize: "0.75rem",
                                            fontWeight: "700"
                                        }}>
                                        </Highlighter>
                                        {hasDuplicate && <Tooltip title={<h3>We found other time series similar to this one.  You can select and merge them into this one, before exporting to Excel.</h3>}>
                                            <CallMergeOutlinedIcon sx={{ color: "var(--brand-complementary)" }} /></Tooltip>}
                                    </Stack>}
                                sx={{ textAlign: 'left' }}
                            />
                        </FormGroup>
                    </Grid>
                    {/* <Grid item lg={2} xl={2} padding={1}>
                        <Tooltip title={<h3>Reported in units of {data['unit']}</h3>}>
                            <Typography sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                fontSize: "0.9rem",
                                textAlign: "left", color: "var(--mui-palette-grey-600)"
                            }}>
                                {data['unit']}
                            </Typography>
                        </Tooltip>
                    </Grid>
                    <Grid item lg={1} xl={1} padding={1}>
                        <Tooltip title={<h3>Reporting frequency</h3>}>
                            <Typography component={'span'} display="flex" sx={{
                                fontSize: "0.9rem",
                                textAlign: "left",
                                color: "var(--mui-palette-grey-600)"
                            }}>
                                {
                                    data['modifier'] === "fiscal years"
                                        ? "annual"
                                        : data['modifier'] === 'twelve months ended'
                                            ? "annual"
                                            : data['modifier'] === "quarters ended"
                                                ? "quarterly"
                                                : data['modifier']
                                }
                            </Typography>
                        </Tooltip>
                    </Grid> */}
                    <Grid item lg={1.5} xl={1.5} padding={1}>
                        {containsLatestFiling && (
                            <Tooltip title={<h3>A fresh datapoint was reported in the most recent filing.</h3>} sx={{ p: 100, ml: 100, }}>
                                <Chip label="Recent" size="small" variant="outlined" sx={{ overflow: "hidden", fontSize: "0.75rem", color: "var(--brand-analogous)" }} />
                            </Tooltip>
                        )}
                        {/* </Stack> */}
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails sx={{ width: "96%" }}>
            <OnboardingWizard step={5} placement="right-end">
                <div>
                <DenseTable data={data} highlightValue={filterValue} ticker={ticker}></DenseTable>
                </div>
                </OnboardingWizard>
            </AccordionDetails>
        </Accordion>

    )
}

export function TableList({ data, displayMask, filterValue, sectionHeaders }) {
    const [sectionExpandState, setSectionExpandState] = useState([]);
    const [tableSeriesExpandState, setTableSeriesExpandState] = useContext(TableSeriesExpandStateContext);
    const [selectedDatapoint, setSelectedDatapoint] = useContext(SelectedDatapointContext);
    const [ticker, formType] = useContext(SearchBarContext);
    const [fromDate, toDate] = useContext(DateRangeContext);
    const [displayedCik, setDisplayedCik] = useContext(DisplayedCikContext);
    const [latestFilingData, setLatestFilingData] = useState([]);
    const [accessToken, setAccessToken] = useState(null);


    // On initial render
    useEffect(
        () => {
            Auth.currentSession()
                .then(resp => setAccessToken(resp.getIdToken().getJwtToken()))
                .catch(error => { console.log(error); })
        },
        []
    );

    // Latest filing API call
    useEffect(
        () => {
            if (displayedCik !== null) {
                const requestPayload = {
                    "cik": displayedCik,
                };

                makePostRequest(process.env.REACT_APP_API_BASE_URL + '/latest_filing', accessToken, requestPayload)
                    .then(response => {
                        setLatestFilingData(response);
                    })
                    .catch(error => { console.log(error); })
            }
        },
        [accessToken, displayedCik]
    );

    // Set the section expansion state
    useEffect(
        () => {
            if (sectionHeaders === null) {
                setSectionExpandState([]);
            } else {
                // If filterValue is not empty,
                // we should open all the relevant sections!
                // (1) Section/Subsection contains filterValue; OR
                // (2) Timeseries contains filterValue

                let tmp = sectionHeaders.map((x, i) => {
                    // Check if any element in data has sectionIdx === i and subSection === "Item 1"
                    const hasFilterValueSubSection = data.some(ts => ts.sectionIdx === i && (ts.subSection.toLowerCase().includes(filterValue.toLowerCase()) || ts.section.toLowerCase().includes(filterValue.toLowerCase())));
                    const hasFormattedDescription = data.some(ts => {
                        const formattedDescription = ts['description'];
                        return formattedDescription.toLowerCase().includes(filterValue.toLowerCase());
                      });
                    const hasFilterValueCell = data.some((d, idx) => { return d['chartTitle'].toLowerCase().includes(filterValue.toLowerCase())});
                    // Set the element in tmp to true if the condition is met, otherwise false
                    return hasFilterValueCell || hasFormattedDescription || hasFilterValueSubSection ? true : false;
                  });
                tmp.push(false);
                setSectionExpandState(tmp);
                // Expand all the tables with filterValue
                // if (data != null) {
                //     // NOTE This is still heavy?
                //     let hasKeyword = data.map((d, idx) => { return d['chartTitle'].toLowerCase().includes(filterValue.toLowerCase())});
                //     const tmp = [...tableSeriesExpandState];
                //     for (let i = 0; i < hasKeyword.length; i++) {
                //         tmp[i] = hasKeyword[i];
                //       }
                //     setTableSeriesExpandState(tmp);
                        
                // }

            }

        }, [sectionHeaders, filterValue]
    )

    // // Set the section expansion state
    // useEffect(
    //     () => {
    //         if (sectionHeaders === null) {
    //             setSectionExpandState([]);
    //         } else {
    //             let tmp = sectionHeaders.map((x, i) => { return i === 0; });
    //             tmp.push(false);
    //             setSectionExpandState(tmp);
    //         }

    //     }, [sectionHeaders]
    // )

    const fd = new Date(fromDate).toLocaleDateString('en-US');
    const td = new Date(toDate).toLocaleDateString('en-US');


    if (data === undefined || data === null ||
        (data && data.length > 0 && data[0]['tableSeries'][0]['cell'][0]['isInDateRange'] === undefined) || /* This condition is needed because date filtering is run on a useEffect in DataPane, which means a render will happen before isInDataRange ever gets populated. */
        sectionHeaders === undefined || sectionHeaders === null) {
        return (
            <Stack alignItems="center" justifyContent="center" height="100%">
                <CircularProgress disableShrink={true} />
            </Stack>
        )
    } else if (Array.isArray(data) && (data.length === 0 || data.every(ts => ts['shouldDisplay'] === false))) {
        setSelectedDatapoint(null);
        ampli.noQueryResult({ "Query Date Range": [fd, td], "Query Form Type": formType, "Query Symbol": ticker });
        return (
            <Stack alignItems="center" justifyContent="center" height="100%">
                <Typography variant="h5" size="large" sx={{ mt: 1.5, color: "var(--brand-main)", fontWeight: '750' }}>No results found.</Typography>
                <Typography sx={{ mt: 1.75, color: "var(--brand-text-secondary)", fontWeight: '750' }}>Our dataset is constantly expanding! </Typography>
                <Typography sx={{ color: "var(--brand-text-secondary)" }} >Check back later or try adjusting your search parameters.</Typography>
            </Stack>
        )
    }
    // When there is some data, and every the displayable data is masked.
    else if (Array.isArray(data) && data.every((ts, index) => (!ts['shouldDisplay'] || displayMask.has(index)  && !ts.subSection.toLowerCase().includes(filterValue.toLowerCase()) && !ts.section.toLowerCase().includes(filterValue.toLowerCase())))) {
        ampli.noKeywordSearchResult({ "Query Date Range": [fd, td], "Query Form Type": formType, "Query Symbol": ticker, "Keyword": filterValue });
        return (
            <Stack alignItems="center" justifyContent="center" height="100%" width="75%">
                <Typography variant="h5" size="large" sx={{ mt: 1.5, color: "var(--brand-main)", fontWeight: '750' }}>No results found.</Typography>
                <List dense={true} sx={{ "padding": "30px", listStyleType: 'disc' }}>
                    <ListItem disableGutters sx={{ fontWeight: '350', display: 'list-item' }}>
                        <ListItemText
                            primaryTypographyProps={{ fontSize: '1.1rem' }}
                            primary="Shortening your filter to include only a specific word could help. Our keyword filter matches KPI names exactly." />
                    </ListItem>
                    <ListItem disableGutters sx={{ fontWeight: '350', display: 'list-item' }}>
                        <ListItemText
                            primaryTypographyProps={{ fontSize: '1.1rem' }}
                            primary="Did you know that you can filter KPIs based on their units? Simply enclose the units within parentheses." />
                    </ListItem>
                    <ListItem disableGutters sx={{ fontWeight: '350', display: 'list-item' }}>
                        <ListItemText
                            primaryTypographyProps={{ fontSize: '1.1rem' }}
                            primary="Using different keywords with similar meaning may return more results." />
                    </ListItem>
                    <ListItem disableGutters sx={{ fontWeight: '350', display: 'list-item' }}>
                        <ListItemText
                            primaryTypographyProps={{ fontSize: '1.1rem' }}
                            primary="Are you looking for a KPI that is usually reported in a table?  We're still working on this feature and plan on releasing it during the Beta Program." />
                    </ListItem>
                    <ListItem disableGutters sx={{ fontWeight: '350', display: 'list-item' }}>
                        <ListItemText
                            primaryTypographyProps={{ fontSize: '1.1rem' }}>
                            Still can't find the KPI you're looking for?  We may be able to help.  Please e-mail us at <a href="mailto:support@revelata.com" target="_blank" rel="noreferrer">support@revelata.com</a>.
                        </ListItemText>
                    </ListItem>

                </List>
            </Stack>
        )
    }
    else {

        const flipChartExpandState = (idx, displayedTimeSeriesLength, hasDuplicate, containsLatestFiling) => {
            const tmp = [...tableSeriesExpandState];
            tmp[idx] = !tmp[idx];
            setTableSeriesExpandState(tmp);

            ampli.timeSeriesChartViewExpansion({
                "Query Symbol": ticker,
                "Chart Title": data[idx].chartTitle,
                "Description": data[idx].description,
                "Doc Type": data[idx].docType,
                "Form Type": data[idx].formType,
                "Hint": data[idx].hint,
                "Modifier": data[idx].modifier,
                "Score": data[idx].score.aggregated,
                "Section Idx": data[idx].sectionIdx,
                "Unit": data[idx].unit,
                "Chart Index": idx,
                "Series Length": displayedTimeSeriesLength,
                "Contains Latest Filing": containsLatestFiling,
                "Expanded": tmp[idx],
                "Has Duplicate Time Series": hasDuplicate
            })
        }

        const onClickSection = (sectionIdx, sectionName, expanded) => {
            ampli.sectionClick({ "Section Name": sectionName, Expanded: expanded });
            const tmp = [...sectionExpandState];
            tmp[sectionIdx] = !tmp[sectionIdx];
            setSectionExpandState(tmp);
            setSelectedDatapoint(null);
        }

        const sectionHeadersWithIndex = sectionHeaders.map((section, index) => (
            { index, name: section }
        ))
            .concat({ index: -1, name: "Other" });

        let sectionIdCountMap = {};
        data.forEach((item, idx) => {
            if (item.shouldDisplay && (!displayMask.has(idx)|| item.subSection.toLowerCase().includes(filterValue.toLowerCase())) || item.section.toLowerCase().includes(filterValue.toLowerCase())) {
                sectionIdCountMap[item.sectionIdx] = (sectionIdCountMap[item.sectionIdx] || 0) + 1;
            }
        });


        const shouldUseSection = !hasOnlyMinusOneKey(sectionIdCountMap);
        const duplicateIndexes = findDuplicateCharts(data, displayMask);
        const firstDisplayedChart = data.findIndex((ts, idx) => !displayMask.has(idx) && ts['shouldDisplay']);
        const uniqueSubSections = Array.from(data.reduce((set, ts) => set.add(ts.subSection), new Set()));
        // NOTE This is sorting subSection alphabetically. But this is not in order in document!
        // uniqueSubSections.sort();

        return (
            <Paper>
            <Stack spacing={0}>
            {
                        shouldUseSection &&
                        <Stack direction="row" alignItems="center" justifyContent="left" width="100%" sx={{ pl: 1 }}>
                            <Typography sx={{ fontSize: "1.0rem", fontWeight: 'bold' }}>Table of Contents</Typography>
                        </Stack>
                    }
                <Paper variant="elevation" elevation={24}   sx={{ml: 1, mt: 1}}>
                {
                        sectionHeadersWithIndex.map(
                            (section, sectionIdx) => {  // we use sectionIdx instead of section.index to index into sectionExpandState
                                const hasSectionData = (sectionIdCountMap[section.index] || 0) > 0;
                                if (!hasSectionData) {
                                    return null;
                                }
                                return (
                                    <Accordion
                                        key={sectionIdx}
                                        expanded={sectionExpandState[sectionIdx] ?? false}
                                        onChange={(e, expanded) => { onClickSection(sectionIdx, section.name, expanded) }}
                                        sx={{ mr: 0 }}
                                        disableGutters>
                                        {
                                            shouldUseSection && (
                                                <AccordionSummary sx={{ mr: 0 }} expandIcon={<ExpandMoreIcon sx={{ color: "var(--brand-main)" }} />}>
                                                    <Stack direction="row" display="flex" justifyContent="space-between" width="100%">
                                                        <Highlighter text={section.name} keyword={filterValue} sx={{ pt: 0, m: 0, textAlign: "left", color: "var(--brand-main)", fontSize: "1.0rem", fontWeight: 'bold' }}></Highlighter>
                                                        <Typography sx={{ p: 0, mr: 0, textAlign: "right", color: "var(--brand-main)" }}>({sectionIdCountMap[section.index] || 0} time series)</Typography>
                                                    </Stack>
                                                </AccordionSummary>
                                            )
                                        }
                                        <Stack spacing={1}>
                                            {uniqueSubSections.map((subSection, subSectionIdx) => {
                                                const hasData = data.some((ts, idx) => // Use `some` to check for any matching data
                                                    !displayMask.has(idx) && ts['shouldDisplay'] && ts.sectionIdx === section.index && subSection === ts.subSection
                                                );
                                                const isKeywordIncludedSection = data.some((ts, idx) => // Use `some` to check for any matching data
                                                ts['shouldDisplay'] &&ts.sectionIdx === section.index && subSection === ts.subSection && ( subSection.toLowerCase().includes(filterValue.toLowerCase()) || section.name.toLowerCase().includes(filterValue.toLowerCase()))
                                                );

                                                if (hasData || isKeywordIncludedSection) {
                                                    return (
                                                        <Stack key={`subSection-${subSectionIdx}`}>
                                                            {subSection !== "" && (<Highlighter text={subSection} keyword={filterValue} sx={{ maxHeight: "25px", paddingLeft: "30px", paddingTop: "30px", paddingBottom: "30px", pt: 1, m: 0, textAlign: "left", color: "var(--brand-analogous-50)", fontSize: "1.0rem", fontWeight: 'bold' }}></Highlighter>)}
                                                            <Paper variant="elevation" elevation={24} square={false}  sx={{mr: 4, ml:4}}>
                                                            {
                                                                data.map(
                                                                    (ts, idx) => {
                                                                        if ((!displayMask.has(idx) || isKeywordIncludedSection) && ts['shouldDisplay'] && ts.sectionIdx === section.index && subSection === ts.subSection) {
                                                                            return <TableSeriesView
                                                            key={"tscv" + idx.toString()}
                                                            idx={idx}
                                                            isFirstChart={firstDisplayedChart === idx}
                                                            data={ts}
                                                            // minX={minDate.getTime()}
                                                            // maxX={maxDate.getTime()}
                                                            expanded={tableSeriesExpandState[idx] ?? false}
                                                            flipExpandedState={flipChartExpandState}
                                                            latestFilingData={latestFilingData}
                                                            hasDuplicate={duplicateIndexes.includes(idx)}
                                                            ticker={ticker}
                                                            filterValue={filterValue}
                                                        />
                                                                        }
                                                                        return null;
                                                                    }
                                                                )
                                                            }
                                                            </Paper>
                                                        </Stack>
                                                    )
                                                } else {
                                                    return null;
                                                }
                                            })}

                                        </Stack>
                                    </Accordion>
                                )
                            }
                        )
                    }
                    </Paper>
            </Stack>
            </Paper>
        )
    }
};

export default TableList;